<template>
  <div>
    <validation-observer ref="formValidation">
      <b-form @submit.prevent>
        <template v-if="creation == true || recipeId">
          <b-form-group label-for="input-status">
            <b-form-select v-model="form.status" placeholder="Statut">
              <b-form-select-option
                v-for="option in ['ENABLED', 'DISABLED']"
                :key="option"
                :value="option"
              >
                {{ $t(`RECIPE.${option}`) }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group label-for="input-status">
            <b-form-checkbox
              v-model="form.best_seller"
              name="check-button"
              switch
            >
              Meilleure vente
            </b-form-checkbox>
          </b-form-group>
          <b-form-group label-for="input-label">
            <validation-provider
              #default="{ errors }"
              name="Ingrédient"
              rules="required"
            >
              <b-form-input
                id="input-label"
                v-model="form.label"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="Intitulé de la recette"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="input-label">
            <b-form-input
              id="input-label"
              v-model="form.label_secondary"
              type="text"
              placeholder="Intitulé de la recette Rutabago"
            />
          </b-form-group>
        </template>
        <template v-if="recipeId">
          <b-form-group label-for="input-scheme">
            <b-form-select v-model="form.scheme" placeholder="Destination">
              <template #first>
                <b-form-select-option :value="null" disabled>
                  -- Destination --
                </b-form-select-option>
              </template>
              <b-form-select-option
                v-for="option in form.schemes"
                :key="option"
                :value="option"
              >
                {{ $t(`RECIPE_SCHEMES.${option}`) }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group label-for="input-dish">
            <b-form-select v-model="form.dish" placeholder="Type de plat">
              <template #first>
                <b-form-select-option :value="null" disabled>
                  -- Type de plat --
                </b-form-select-option>
              </template>
              <b-form-select-option
                v-for="option in form.dishes"
                :key="option"
                :value="option"
              >
                {{ $t(`RECIPE_DISHES.${option}`) }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group label-for="input-diet">
            <b-form-select v-model="form.diet" placeholder="Régime">
              <template #first>
                <b-form-select-option :value="null" disabled>
                  -- Type de plat --
                </b-form-select-option>
              </template>
              <b-form-select-option
                v-for="option in form.diets"
                :key="option"
                :value="option"
              >
                {{ $t(option) }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group label-for="input-category">
            <b-form-select v-model="form.category" placeholder="Catégorie">
              <template #first>
                <b-form-select-option :value="null" disabled>
                  -- Catégorie --
                </b-form-select-option>
              </template>
              <b-form-select-option
                v-for="option in form.categories"
                :key="option"
                :value="option"
              >
                {{ $t(`RECIPE_CATEGORIES.${option}`) }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group label="Ingrédients principaux">
            <b-form-checkbox-group
              v-model="form.categories_list"
              name="difficulty"
            >
              <b-form-checkbox
                v-for="option in form.categories_choices"
                :key="option"
                :value="option"
              >
                {{ $t(`RECIPE_CATEGORIES.${option}`) }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
          <b-form-group label="Saisons">
            <b-form-checkbox-group
              v-model="form.seasons_list"
              name="difficulty"
            >
              <b-form-checkbox
                v-for="option in form.seasons"
                :key="option"
                :value="option"
              >
                {{ $t(option) }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
          <!-- <b-form-group label-for="input-label">
            <b-form-select v-model="form.season" placeholder="Saison">
              <template #first>
                <b-form-select-option :value="null" disabled>
                  -- Saison --
                </b-form-select-option>
              </template>
              <b-form-select-option
                v-for="option in form.seasons"
                :key="option"
                :value="option"
              >
                {{ $t(`${option}`) }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group> -->
          <b-form-group label-for="input-description">
            <b-form-textarea
              id="input-description"
              v-model="form.description"
              rows="4"
              placeholder="Description"
            />
          </b-form-group>
          <b-form-group label-for="input-notes">
            <b-form-textarea
              id="input-notes"
              v-model="form.notes"
              rows="4"
              placeholder="Notes"
            />
          </b-form-group>
          <b-form-group label="Difficulté cuisson">
            <b-form-radio-group
              v-model="form.difficulty"
              :options="[
                { text: 1, value: 1 },
                { text: 2, value: 2 },
                { text: 3, value: 3 },
                { text: 4, value: 4 },
                { text: 5, value: 5 },
              ]"
              name="difficulty"
            />
          </b-form-group>
          <b-form-group label="Difficulté barquettage">
            <b-form-radio-group
              v-model="form.assemblage_difficulty"
              :options="[
                { text: 1, value: 1 },
                { text: 2, value: 2 },
                { text: 3, value: 3 },
                { text: 4, value: 4 },
                { text: 5, value: 5 },
              ]"
              name="assemblage_difficulty"
            />
          </b-form-group>
          <b-form-group label="Nutri score">
            <b-form-radio-group
              v-model="form.nutri_score"
              :options="[
                { text: 'A', value: 'A' },
                { text: 'B', value: 'B' },
                { text: 'C', value: 'C' },
                { text: 'D', value: 'D' },
                { text: 'E', value: 'E' },
              ]"
              name="nutri_score"
            />
          </b-form-group>
          <b-form-group label="Eco score">
            <b-form-radio-group
              v-model="form.eco_score"
              :options="[
                { text: 'A', value: 'A' },
                { text: 'B', value: 'B' },
                { text: 'C', value: 'C' },
                { text: 'D', value: 'D' },
                { text: 'E', value: 'E' },
              ]"
              name="eco_score"
            />
          </b-form-group>
        </template>
        <hr />
        <div class="row mt-3">
          <div class="col-md-6">
            <span v-if="creation === true">
              <b-button
                variant="primary"
                class="mr-1"
                @click.prevent="validationForm()"
              >
                Créer
              </b-button>
            </span>
            <span v-else>
              <span
                v-if="$store.getters['user/profile'].role == 'ADMINISTRATOR'"
              >
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click.prevent="update()"
                >
                  Enregistrer
                </b-button>
              </span>
              <span v-else>
                <b-button v-b-modal.modal-update variant="primary" class="mr-1">
                  Enregistrer
                </b-button>
              </span>
            </span>
            <b-button
              variant="light"
              class="mr-1"
              @click.prevent="$emit('update:display', false)"
            >
              Annuler
            </b-button>
          </div>
          <div class="col-md-6 text-right">
            <b-button
              v-if="recipeId"
              variant="info"
              class="mr-1"
              @click.prevent="duplicateRecipe()"
            >
              Dupliquer
            </b-button>
            <b-button v-if="recipeId" v-b-modal.modal-delete variant="danger">
              Supprimer
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
    <b-modal
      id="modal-delete"
      ref="delete-recipe"
      centered
      hide-header-close
      title="Suprimmer la recette"
    >
      <p class="my-2">Confirmez-vous la suppression de cet élément ?</p>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="danger" @click="deleteRecipe()">
          Confirmer
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          Annuler
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-update"
      ref="update-recipe"
      centered
      hide-header-close
      title="Enregistrer la recette"
    >
      <p class="my-2">
        Confirmez-vous la modification de cette recette ? <br />
        Toute modification de cette recette va entrainer des changements sur le
        calcul des prix et l'affichage dans le site internet.
      </p>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button
          size="sm"
          variant="danger"
          @click="
            update()
            hide('forget')
          "
        >
          Confirmer
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          Annuler
        </b-button>
      </template>
    </b-modal>
    <!-- <pre>{{ form }} </pre> -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, double } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormRadioGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BButton,
  BModal,
} from 'bootstrap-vue'

export default {
  name: 'RecipeForm',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormTextarea,
    BFormRadioGroup,
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  inheritAttrs: false,
  props: {
    creation: {
      default: false,
      type: Boolean,
    },
    recipeId: {
      default: null,
      type: String,
    },
    display: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      required,
      double,
      locale: 'fr',
      form: {
        status: null,
        best_seller: null,
        label: null,
        label_secondary: null,
        scheme: null,
        description: null,
        notes: null,
        difficulty: null,
        assemblage_difficulty: null,
        seasons_list: null,
        category: null,
        categories_list: [],
        dish: null,
        diet: null,
        nutri_score: null,
        eco_score: null,
      },
    }
  },
  watch: {
    recipeId() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      if (this.recipeId) {
        const resp = await this.$http.get(`/recipes/${this.recipeId}/`, {
          headers: {
            'X-Fields': `${Object.keys(this.form).join(
              ','
            )},seasons,categories,categories_choices,dishes,schemes,diets`,
          },
        })
        this.form = resp.data
      }
    },
    validationForm() {
      this.$refs.formValidation.validate().then((success) => {
        console.log(success)
        if (success === true && this.creation === true) {
          this.create()
        } else if (success === true && this.recipeId) {
          this.update()
        }
      })
    },
    async create() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.post(
          '/recipes/',
          { status: 'ENABLED', ...this.form },
          {
            headers: {
              'X-Fields': 'id',
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$emit('created', resp.data)
      } catch (e) {
        console.log(e)
      }
    },
    async update() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.put(
          `/recipes/${this.recipeId}/`,
          this.form,
          {
            headers: {
              'X-Fields': 'id',
              'X-Keys': Object.keys(this.form).join(','),
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        console.log(JSON.parse(JSON.stringify(resp.data)))
        // this.$emit('updated', resp.data)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Enregistrement effectué',
              icon: 'SaveIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          }
        )
      } catch (e) {
        console.log(e)
      }
    },
    async duplicateRecipe() {
      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.post(
        `/recipes/${this.recipeId}/duplicate/`,
        {},
        {
          headers: {
            'X-Fields': 'id',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        }
      )
      this.$emit('duplicated', resp.data)
    },
    async deleteRecipe() {
      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.delete(`/recipes/${this.recipeId}/`, {
        headers: {
          'X-Fields': 'id',
          'X-Keys': 'id',
          'X-CSRF-TOKEN': respCsrf.data.csrf,
        },
      })
      this.$emit('deleted', resp.data)
      this.$refs['delete-recipe'].hide()
    },
  },
}
</script>
