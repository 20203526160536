<template>
  <b-card title="">
    <div class="card-title">
      <div class="row">
        <div class="col-md-6">
          <h4 class="mb-0">{{ title }}</h4>
        </div>
        <div class="col-md-6 text-right">
          <button
            class="btn btn-info btn-icon mr-1"
            @click.prevent="displayForm = !displayForm"
          >
            <feather-icon icon="PlusIcon" />
          </button>
          <a
            :href="`${
              themeConfig.apiUrl
            }/recipes/exports/recipes.xlsx?d=${new Date()}`"
            class="btn btn-info btn-icon mr-1"
          >
            Export
          </a>
          <a
            :href="`${
              themeConfig.apiUrl
            }/recipes/exports/recipes.xlsx?client=RUTABAGO&d=${new Date()}`"
            class="btn btn-info btn-icon mr-1"
          >
            Export Rutabago
          </a>
          <button
            class="btn btn-info btn-icon"
            @click.prevent="displayFilters = !displayFilters"
          >
            <feather-icon icon="FilterIcon" />
          </button>
        </div>
      </div>
    </div>
    <div class="m-lg-5 mb-5">
      <recipes-filters
        v-if="displayFilters"
        :filters.sync="filters"
        @updated="setPage(1)"
      />
    </div>
    <div v-if="displayForm == true" class="m-lg-5 mb-5">
      <h3>Ajouter une recette</h3>
      <recipe-form
        :creation="true"
        :display.sync="displayForm"
        @created="onCreate"
      />
    </div>
    <pagination-custom
      :page="filters.page"
      :total="pagination.total"
      :per-page="filters.per_page"
      class="text-center mb-1"
      @change="setPage"
    />
    <table class="table table-hover">
      <tbody>
        <template v-for="recipe in recipes">
          <tr
            :key="recipe.id"
            @click.prevent="$router.push(`/recipes/${recipe.id}`)"
          >
            <td style="max-width: 100px; vertical-align: top">
              <b-img
                :src="`${recipe.thumb_list_url}?d=${new Date()}`"
                fluid
                style="max-width: 90px"
              />
            </td>
            <td>
              <h4>
                <font-awesome-icon
                  v-if="recipe.status == 'DISABLED'"
                  icon="eye-slash"
                  class="text-danger"
                />
                <font-awesome-icon
                  v-if="recipe.is_publishable == false"
                  icon="flag"
                  class="text-danger"
                />
                {{ recipe.label }}
                <br />
                <small>
                  <b-badge
                    v-if="recipe.scheme"
                    pill
                    variant="info"
                    class="mr-1"
                  >
                    {{ $t(`RECIPE_SCHEMES.${recipe.scheme}`) }}
                  </b-badge>
                  <b-badge v-if="recipe.dish" pill variant="info" class="mr-1">
                    {{ $t(`RECIPE_DISHES.${recipe.dish}`) }}
                  </b-badge>
                  <b-badge
                    v-if="recipe.season"
                    pill
                    variant="info"
                    class="mr-1"
                  >
                    {{ recipe.season }}
                  </b-badge>
                  <b-badge
                    v-if="recipe.difficulty"
                    pill
                    variant="info"
                    class="mr-1"
                  >
                    Difficulté cuisson {{ recipe.difficulty }}
                  </b-badge>
                  <b-badge
                    v-if="recipe.assemblage_difficulty"
                    pill
                    variant="info"
                    class="mr-1"
                  >
                    Difficulté barquettage {{ recipe.assemblage_difficulty }}
                  </b-badge>
                  <b-badge v-if="recipe.nutri_score" pill variant="secondary">
                    Nutriscore {{ recipe.nutri_score }}
                  </b-badge>
                </small>
              </h4>
              <p class="text-muted">{{ recipe.description }}</p>
            </td>
            <td>
              {{ $dayjs(recipe.created_at).format('DD/MM/YYYY') }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <pagination-custom
      :page="filters.page"
      :total="pagination.total"
      :per-page="filters.per_page"
      class="text-center mt-3"
      @change="setPage"
    />
    <!-- <pre>{{ recipes }}</pre> -->
    <!-- <pre>{{ pagination }}</pre> -->
    <!-- <pre>{{ filters }}</pre> -->
  </b-card>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import { BCard, BBadge, BImg } from 'bootstrap-vue'
import RecipeForm from '@/components/RecipeForm.vue'
import RecipesFilters from './RecipesFilters.vue'

export default {
  components: {
    BCard,
    BBadge,
    RecipeForm,
    RecipesFilters,
    BImg,
  },
  data() {
    return {
      themeConfig: $themeConfig,
      displayFilters: false,
      displayForm: false,
      recipes: [],
      pagination: { total: 0 },
      filters: {
        page: 1,
        per_page: 25,
        term: null,
      },
    }
  },
  computed: {
    title() {
      if (this.pagination.total) {
        return `${this.pagination.total} éléments - page ${this.pagination.page} / ${this.pagination.pages}`
      }
      return '…'
    },
  },
  watch: {
    $route() {
      this.init()
    },
  },
  async created() {
    this.filters = Object.assign(this.filters, this.$route.query)
    this.init()
  },
  methods: {
    async init() {
      const resp = await this.$http.get('/recipes/', {
        params: Object.assign(this.filters, this.$route.query),
        headers: {
          'X-Fields':
            'results{' +
            'id,label,status,scheme,dish,description,nutri_score,eco_score,' +
            'is_publishable,category,season,difficulty,assemblage_difficulty,created_at,thumb_list_url' +
            '},meta',
        },
      })
      this.recipes = resp.data.results
      this.pagination = resp.data.meta
      this.$forceUpdate()
      this.scrollToTop()
    },
    setPage(page) {
      if (page) {
        this.filters.page = page
      }
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: this.filters,
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onCreate(recipe) {
      this.displayForm = false
      this.$router.push(`/recipes/${recipe.id}/`)
    },
  },
}
</script>

<style></style>
